<template>
  <div v-if="!$_loading_state" class="p-coffeeMap">
    <a :href="tutorialUrl" class="p-coffeeMap__help">
      <Icon name="helpCircle" />
    </a>

    <div class="p-coffeeMap__myCoffeeMapWrapper">
      <MyCoffeeMap
        :map-values="mapValues"
        :active-cell-id="activeCellId"
        :color="color"
        :overlay="needPreferenceTest"
        :is-colored="!needPreferenceTest"
        @click:cell="updateQuery"
        @click:overlay="startTest" />
    </div>

    <p class="p-coffeeMap__note">
      ※味覚マップのコーヒーのデータは、「味覚センサー」分析により一定基準の元で計測しております。各製品や店頭の味覚表記とは異なる場合がございます。
    </p>

    <RecommendBottomSheet
      ref="bottomSheet"
      :products="products"
      :message="recommendMessage"
      :paginatable="!!nextPageUrl"
      :show-close-icon="!!activeCellId"
      :need-test="needPreferenceTest"
      @load-more="loadMore"
      @close="deactivateCell"
      @start-test="startTest" />
  </div>
</template>

<script>
/**
 * NOTE: クエリパラメータに `cell_id` が指定されている場合は、指定セルをアクティブにして表示
 */

import { cloneDeep } from 'lodash';
import { SystemDialogMessage } from '@/constants/enums';

const COLOR = {
  MIN: { HUE: 28, SATURATION: 100, LIGHTNESS: 93 },
  MAX: { HUE: 29, SATURATION: 100, LIGHTNESS: 50 },

  /** 味覚診断の結果のみでコーヒーマップを描画する場合に用いる最も濃い色 */
  MAX2: { HUE: 29, SATURATION: 100, LIGHTNESS: 86 }
};

const MIN_CELL_ID = 1;
const MAX_CELL_ID = 36;

export default {
  components: {
    MyCoffeeMap: () => import('@/components/mypage/coffeeMap/MyCoffeeMap'),
    RecommendBottomSheet: () =>
      import('@/components/mypage/coffeeMap/RecommendBottomSheet')
  },

  data: () => ({
    mapValues: Array(36).fill({
      id: null,
      val: null,
      count: { like: 0, hate: 0 }
    }),

    customer: null,
    /** おすすめコーヒー */
    recommendedProducts: [],
    /** スタイリストメッセージ */
    message: '',
    /** @type {number | null} 1〜36 */
    activeCellId: null,
    nextPageUrl: null
  }),

  computed: {
    /**
     * 過去に「好き」の感想登録があるかどうか
     */
    hasLikeImpression() {
      return this.mapValues.findIndex((v) => v.count.like > 0) !== -1;
    },

    color() {
      // 味覚診断の結果のみでコーヒーマップを描画する場合は、描画色を変更する
      if (
        this.customer?.is_preference_test_completed &&
        !this.hasLikeImpression
      ) {
        return {
          MIN: COLOR.MIN,
          MAX: COLOR.MAX2
        };
      }

      return {
        MIN: COLOR.MIN,
        MAX: COLOR.MAX
      };
    },

    tutorialUrl() {
      return this.$customUrlScheme.dialog(
        'coffeemap-tutorial',
        `eclink=${this.customer?.ec?.is_alignment ? 1 : 0}`
      );
    },

    recommendMessage() {
      return this.activeCellId === null ? this.message : '';
    },

    /**
     * 味覚診断をする必要があるかどうか（味覚診断は必須）
     */
    needPreferenceTest() {
      return !this.customer?.is_preference_test_completed;
    },

    products() {
      return this.activeCellId
        ? this.mapValues[this.activeCellId - 1]?.products || []
        : this.recommendedProducts;
    }
  },

  watch: {
    // セルの選択状態の変更を監視
    '$route.query': {
      handler() {
        this.setActiveCellId();
      },

      immediate: true
    },

    // NOTE: 表示する製品が変更された際に、 BottomSheet のヘッダーの高さを再計算する
    products() {
      this.$refs.bottomSheet?.calcHeaderHeight();
    }
  },

  created() {
    this.init();
  },

  methods: {
    /**
     * コーヒーマップ情報を取得
     */
    async _getCoffeeMap() {
      const { data } = await this.$repositories('coffeeMap').get();

      // コーヒーマップ
      this.mapValues = data.coffee_map.map((item) => ({
        id: item.map_position,
        val: item.val,
        count: {
          like: item.is_like,
          hate: item.is_hate
        },
        products: item.products.filter((item) => !!item['ec_link_url'])
      }));

      // スタイリストメッセージ
      this.message = data.recommend_message.message;
    },

    /**
     * おすすめコーヒー一覧を取得
     */
    async _getRecommendedProducts() {
      const { data } = await this.$repositories('coffeeMap').getRecommended(
        this.$utilities.getUrlSearch(this.nextPageUrl)
      );
      this.recommendedProducts.push(...data.data);
      this.nextPageUrl = data.next_page_url;
    },

    async _getCustomer() {
      const { data } = await this.$repositories('customer').getCustomer();
      this.customer = data;
    },

    async init() {
      try {
        this.$_loading_start();

        await Promise.all([
          this._getCustomer(),
          this._getCoffeeMap(),
          this._getRecommendedProducts()
        ]);
      } catch {
        window.location.href = this.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        this.$_loading_stop();
      }
    },

    async loadMore() {
      if (this.$_loading_state) return;
      await this._getRecommendedProducts();
    },

    /**
     * セルの選択に応じてクエリパラメータを変更する
     * @param {number} clickedCellId クリックされたセルID
     */
    updateQuery(clickedCellId) {
      if (this.activeCellId === clickedCellId) {
        this.deactivateCell();
      } else {
        this.activateCell(clickedCellId);
      }
    },

    /**
     * 指定セルをアクティベートする
     * @param {number} cellId セルID
     */
    activateCell(cellId) {
      this.routerPush(cellId);
    },

    /**
     * 指定セルをディアクティベートする
     */
    deactivateCell() {
      this.routerPush();
    },

    /**
     * クエリパラメータに指定されているセルIDをアクティブセルIDにセットする
     */
    setActiveCellId() {
      const id = Number(this.$route.query.cell_id);
      if (
        isNaN(id) ||
        id < MIN_CELL_ID ||
        id > MAX_CELL_ID ||
        this.needPreferenceTest
      ) {
        this.activeCellId = null;
        return;
      }
      this.activeCellId = id;
    },

    /**
     * 指定したcellIdをクエリパラメータに付与してコーヒーマップに遷移する
     * @param {number} cellId アクティブにするセルID
     */
    routerPush(cellId = undefined) {
      const query = cloneDeep(this.$route.query);
      query.cell_id = cellId;

      this.$router.push({ path: '/mypage/coffeemap', query });
    },

    /**
     * 味覚診断を開始する（実処理はネイティブ側で行う）
     */
    startTest() {
      window.location.href = this.$customUrlScheme.dialog(
        'taste-diagnose',
        `eclink=${this.customer?.ec?.is_alignment ? 1 : 0}`
      );
    }
  }
};
</script>
