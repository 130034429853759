var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$_loading_state
    ? _c(
        "div",
        { staticClass: "p-coffeeMap" },
        [
          _c(
            "a",
            {
              staticClass: "p-coffeeMap__help",
              attrs: { href: _vm.tutorialUrl },
            },
            [_c("Icon", { attrs: { name: "helpCircle" } })],
            1
          ),
          _c(
            "div",
            { staticClass: "p-coffeeMap__myCoffeeMapWrapper" },
            [
              _c("MyCoffeeMap", {
                attrs: {
                  "map-values": _vm.mapValues,
                  "active-cell-id": _vm.activeCellId,
                  color: _vm.color,
                  overlay: _vm.needPreferenceTest,
                  "is-colored": !_vm.needPreferenceTest,
                },
                on: {
                  "click:cell": _vm.updateQuery,
                  "click:overlay": _vm.startTest,
                },
              }),
            ],
            1
          ),
          _c("p", { staticClass: "p-coffeeMap__note" }, [
            _vm._v(
              " ※味覚マップのコーヒーのデータは、「味覚センサー」分析により一定基準の元で計測しております。各製品や店頭の味覚表記とは異なる場合がございます。 "
            ),
          ]),
          _c("RecommendBottomSheet", {
            ref: "bottomSheet",
            attrs: {
              products: _vm.products,
              message: _vm.recommendMessage,
              paginatable: !!_vm.nextPageUrl,
              "show-close-icon": !!_vm.activeCellId,
              "need-test": _vm.needPreferenceTest,
            },
            on: {
              "load-more": _vm.loadMore,
              close: _vm.deactivateCell,
              "start-test": _vm.startTest,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }